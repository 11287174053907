<template>
  <div>
    <Header />
    <div
      class="container mx-auto border border-grey-2 rounded-lg my-10 grid grid-cols-6"
    >
      <!-- Detail Paket Old Body -->
      <aside class="top-0 p-4 col-span-6 md:col-span-1 md:h-screen">
        <form id="form-filter">
          <div class="mb-6">
            <label
              for="select-city"
              class="block mb-2 font-bold text-gray-900 dark:text-gray-400"
              >Select City</label
            >
            <select
              id="select-city"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              v-if="listKota.length > 0"
              v-model="idWilayah"
              v-on:change="handleCityChange"
            >
              <option value="">Select City</option>
              <option
                v-for="rowKota in listKota"
                :key="rowKota.nama_wilayah"
                :value="rowKota.id_wilayah"
              >
                {{ rowKota.nama_wilayah }}
              </option>
            </select>
          </div>
          <div class="mb-6" v-if="$route.params.slug == 'landadventure'">
            <label class="block mb-2 font-bold text-gray-900 dark:text-gray-300"
              >Select {{ textLabel }} Type</label
            >
            <div class="flex flex-col gap-2">
              <div
                @click="handleRedirect('offroad')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'offroad',
                  'text-gray-500': $route.params.trip != 'offroad',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-center"
              >
                <div class="grid grid-cols-1 content-center">Off Road</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'offroad'"
                  />
                </div>
              </div>
              <div
                @click="handleRedirect('onroad')"
                :class="{
                  'bg-primary text-white': $route.params.trip == 'onroad',
                  'text-gray-500': $route.params.trip != 'onroad',
                }"
                class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-center"
              >
                <div class="grid grid-cols-1 content-center">On Road</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                    v-if="$route.params.trip == 'onroad'"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </aside>
      <div class="flex flex-col p-4 md:col-span-5 col-span-6">
        <div class="flex font-bold px-2">
          <label class="font-bold inline-block align-middle my-auto"
            >Select Package</label
          >
          <div class="flex flex-row gap-2 mx-2">
            <router-link
              :to="{
                name: 'DetailPaket',
                params: {
                  slug: $route.params.slug,
                },
              }"
              class="bg-primary hover:bg-primary text-white font-bold py-1 px-4 border border-primary rounded-xl"
            >
              Day Trip
            </router-link>
            <router-link
              :to="{
                name: 'DetailRental',
                params: {
                  slug: $route.params.slug,
                },
              }"
              :event="$route.params.slug == 'wateradventure' ? 'click' : ''"
              :class="{ disabled: $route.params.slug != 'wateradventure' }"
              class="bg-white hover:bg-primary text-grey-2 hover:text-white font-bold py-1 px-4 border border-grey-2 rounded-xl"
            >
              Rental
            </router-link>
          </div>
        </div>
        <div class="sm:hidden mb-6 px-5">
          <VueSlickCarousel
            v-bind="settings"
            v-if="listPaket.length > 0"
            @afterChange="paketAfterChange"
          >
            <div
              class="flex-shrink-0"
              v-for="paket in listPaket"
              :key="paket.id"
              @click="selectPaket(paket)"
            >
              <CardPaket
                :detail="paket"
                :selectedPaket="selectedPaket"
                :key="paket.id"
              />
            </div>
          </VueSlickCarousel>
        </div>

        <div
          class="sm:flex-row gap-2 overflow-x-auto py-4 px-2 w-full hidden sm:flex"
          v-if="listPaket.length > 0"
        >
          <div
            class="flex-shrink-0"
            v-for="paket in listPaket"
            :key="paket.id"
            @click="selectedPaket = paket"
          >
            <CardPaket
              :detail="paket"
              :selectedPaket="selectedPaket"
              :key="paket.id"
            />
          </div>
        </div>
        <div class="py-4 px-2" v-if="listPaket.length > 0">
          <div class="flex sm:flex-row flex-col justify-between w-full">
            <span class="font-extrabold text-xl md:text-3xl text-primary px-2">
              {{ selectedPaket.nama }}
            </span>

            <div
              class="flex flex-row sm:justify-between justify-end text-lg font-bold gap-2 items-center sm:mt-0 mt-3 sm:mr-0 mr-2"
            >
              <div class="sm:col-span-2">
                <button
                  class="bg-primary rounded-full font-xl h-10 w-10 grid grid-cols-1 content-center items-center justify-items-center"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-message"
                    :style="{ color: 'white' }"
                  />
                </button>
              </div>
              <div class="sm:col-span-3">
                <button
                  class="bg-yellow text-black w-28 sm:w-40 font-extrabold py-2 px-4 rounded-md font-xl"
                  @click="setPackage"
                >
                  BOOK
                </button>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-5 gap-2 px-2 overflow-auto">
            <div class="lg:col-span-2 col-span-5 py-2">
              <div class="mb-2">
                <video
                  :key="selectedPaket.id"
                  :poster="selectedPaket.galeri[0].foto"
                  controls
                  controlsList="nodownload"
                  class="object-cover rounded-2xl max-h-64 w-full"
                >
                  <source :src="selectedPaket.video" type="video/mp4" />
                </video>
              </div>
              <div
                class="flex flex-col gap-4 justify-between text-left text-primary"
              >
                <div>
                  <div
                    class="flex flex-row justify-start text-lg font-bold gap-2 items-center text"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-clock"
                      :style="{ color: 'black', fontSize: '24px' }"
                    />
                    &nbsp; {{ selectedPaket.durasi }} {{ selectedPaket.satuan }}

                    <div class="flex items-center">
                      <img
                        v-if="selectedPaket.level === '3'"
                        src="@/assets/icons/ic-level-advance.svg"
                        class="w-20"
                        :title="selectedPaket.level"
                        :alt="selectedPaket.level"
                      />
                      <img
                        v-else-if="selectedPaket.level === '2'"
                        src="@/assets/icons/ic-level-intermediate.svg"
                        class="w-20"
                        :title="selectedPaket.level"
                        :alt="selectedPaket.level"
                      />
                      <img
                        v-else
                        src="@/assets/icons/ic-level-beginner.svg"
                        class="w-20"
                        :title="selectedPaket.level"
                        :alt="selectedPaket.level"
                      />
                    </div>
                    <span>
                      {{
                        selectedPaket.level === "3"
                          ? "Advance"
                          : selectedPaket.level === "2"
                          ? "Intermediate"
                          : selectedPaket.level === "1" && "Beginner"
                      }}
                    </span>
                  </div>
                </div>
                <div :key="selectedPaket.id">
                  Start From :
                  <span
                    class="font-extrabold line-through mr-2 text-gray-500"
                    v-if="
                      hargaMinDetailPaket.harga !=
                      hargaMinDetailPaket.harga_normal
                    "
                    >{{ hargaMinDetailPaket.harga_normal | toCurrency }}</span
                  >
                  <span class="font-extrabold">{{
                    hargaMinDetailPaket.harga | toCurrency
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="lg:col-span-3 col-span-5 flex-col gap-2 text-sm text-left py-2"
            >
              <div class="mb-4">
                <ul
                  class="flex flex-row md:gap-2 rounded-lg sm:flex text-center text-xs md:text-sm text-gray-500 font-bold"
                  id="detailTab"
                  data-tabs-toggle="#detailTabContent"
                  role="tablist"
                >
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 0"
                    :class="{
                      'bg-white': activeTab == 0,
                      'bg-grey-1': activeTab != 0,
                    }"
                  >
                    <a
                      v-on:click.prevent.stop="activeTab = 0"
                      class="inline-block py-2 px-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 active"
                      >Descriptions</a
                    >
                  </li>
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 1"
                    :class="{
                      'bg-white': activeTab == 1,
                      'bg-grey-1': activeTab != 1,
                    }"
                  >
                    <a
                      v-on:click.prevent.stop="activeTab = 1"
                      class="inline-block py-2 px-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50"
                      >Itinerary</a
                    >
                  </li>
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 2"
                    :class="{
                      'bg-white': activeTab == 2,
                      'bg-grey-1': activeTab != 2,
                    }"
                  >
                    <a
                      v-on:click.prevent.stop="activeTab = 2"
                      class="inline-block py-2 px-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                      >Location</a
                    >
                  </li>
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 3"
                    :class="{
                      'bg-white': activeTab == 3,
                      'bg-grey-1': activeTab != 3,
                    }"
                  >
                    <a
                      v-on:click.prevent.stop="activeTab = 3"
                      class="inline-block py-2 px-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                      >Review({{ totalReview }})</a
                    >
                  </li>
                </ul>
              </div>
              <div id="detailTabContent">
                <div
                  class="p-8 bg-gray-50 rounded-lg dark:bg-gray-800 h-80 overflow-y-auto"
                  :class="{ hidden: activeTab != 0 }"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <p
                    class="text-sm text-gray-500 dark:text-gray-400"
                    v-html="nl2br(selectedPaket.long_desc)"
                  ></p>
                  <div v-if="selectedPaket.galeri.length > 0">
                    <carousel
                      :perPage="2"
                      :scrollPerPage="true"
                      :navigationEnabled="true"
                      :autoplay="false"
                      :paginationEnabled="false"
                    >
                      <slide
                        v-for="(slider, slideridx) in selectedPaket.galeri"
                        :key="slideridx"
                      >
                        <div
                          v-if="slider.hapus == '0'"
                          class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer m-5"
                        >
                          <div>
                            <img
                              :src="slider.foto"
                              :alt="slider.nama"
                              class="object-cover h-40 w-full"
                            />
                          </div>
                        </div>
                      </slide>
                    </carousel>
                  </div>
                  <div v-if="selectedPaket.luggage != ''">
                    <h1 class="font-medium leading-tight text-2xl mt-0 mb-2">
                      Facilities
                    </h1>
                    <p
                      class="text-sm text-gray-500 dark:text-gray-400"
                      v-html="nl2br(selectedPaket.luggage)"
                    ></p>
                  </div>
                  <div
                    class="bg-gray-200 rounded-xl shadow-xl grid grid-cols-2 gap-2 p-2"
                  >
                    <ul class="list-disc pl-6">
                      <li v-if="selectedPaket.adv_book != ''">
                        <span class="font-bold">Booking In Advance</span>
                        <p>
                          Must be book at least {{ selectedPaket.adv_book }} the
                          trip
                        </p>
                      </li>
                      <li>
                        <span class="font-bold">Minimum Age</span>
                        <p>{{ selectedPaket.min_age }} years old</p>
                      </li>
                      <li>
                        <span class="font-bold">Operational Days</span>
                        <div class="grid grid-cols-3 gap-2">
                          <div class="flex flex-col gap">
                            <span>Sun</span>
                            <span>Mon</span>
                            <span>Tue</span>
                          </div>
                          <div class="flex flex-col gap">
                            <span>Wed</span>
                            <span>Thu</span>
                            <span>Fri</span>
                          </div>
                          <div class="flex flex-col gap">
                            <span>Sat</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul class="list-disc pl-6">
                      <li>
                        <span class="font-bold">Operational Time</span>
                        <p>09:00 - 17:00</p>
                      </li>
                      <li>
                        <span class="font-bold">Categories</span>
                        <p v-html="selectedPaket.category"></p>
                      </li>
                      <li>
                        <span class="font-bold">Guide Language</span>
                        <p>{{ selectedPaket.guide }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                  :class="{ hidden: activeTab != 1 }"
                  id="dashboard"
                  role="tabpanel"
                  aria-labelledby="dashboard-tab"
                >
                  <p
                    class="text-sm text-gray-500 dark:text-gray-400"
                    v-html="nl2br(selectedPaket.itinerary)"
                  ></p>
                </div>
                <div
                  class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800 h-80 w-full overflow-auto"
                  :class="{ hidden: activeTab != 2 }"
                  id="settings"
                  role="tabpanel"
                  aria-labelledby="settings-tab"
                >
                  <p>
                    {{ selectedPaket.meeting_point }} -
                    {{ selectedPaket.meeting_address }}
                  </p>
                  <GmapMap
                    :center="{
                      lat: parseFloat(selectedPaket.meeting_latitude),
                      lng: parseFloat(selectedPaket.meeting_longitude),
                    }"
                    :zoom="16"
                    map-type-id="terrain"
                    style="width: 500px; height: 300px"
                  >
                    <GmapMarker
                      :position="
                        google &&
                        new google.maps.LatLng(
                          parseFloat(selectedPaket.meeting_latitude),
                          parseFloat(selectedPaket.meeting_longitude)
                        )
                      "
                      :clickable="true"
                      :draggable="true"
                    />
                  </GmapMap>
                </div>
                <div
                  class="rounded-lg flex flex-col gap-4 p-2 h-60 overflow-y-auto"
                  :class="{ hidden: activeTab != 3 }"
                  id="contacts"
                  role="tabpanel"
                  aria-labelledby="contacts-tab"
                  v-if="listReview.length > 0"
                >
                  <div
                    class="flex flex-col gap-4 shadow-lg rounded-3xl border border-gray-100 p-4 text-base"
                    v-for="(rev, revidx) in listReview"
                    :key="revidx"
                  >
                    <div class="flex flex-row justify-between gap-2">
                      <div
                        class="grid grid-cols-2 gap-2 justify-between font-bold items-center"
                      >
                        <span>
                          <star-rating
                            :rating="parseFloat(rev.bintang)"
                            :increment="0.01"
                            :show-rating="false"
                            :star-size="25"
                            :read-only="true"
                          ></star-rating>
                        </span>
                        <span
                          >By
                          <span class="text-primary">{{
                            rev.namacustomer
                          }}</span></span
                        >
                      </div>
                      <span>{{ dateText(rev.tgl) }}</span>
                    </div>
                    <div class="flex">{{ rev.desc }}</div>
                    <div
                      class="flex flex-row border-t-2 border-gray-300 justify-between pt-2"
                    >
                      <span class="cursor-pointer">
                        <font-awesome-icon
                          icon="fa-regular fa-thumbs-up"
                          class="text-base"
                        />
                        <span v-if="rev.terbantu > 0"
                          >{{ rev.terbantu }} users helped</span
                        ><span v-else>Helped?</span>
                      </span>
                      <span class="cursor-pointer">
                        <font-awesome-icon
                          icon="fa-solid fa-comments"
                          class="text-base"
                        />
                        Discussion
                      </span>
                      <span class="cursor-pointer" @click="handleReply(rev)">
                        More Reply
                        <font-awesome-icon
                          icon="fa-solid fa-angle-down"
                          class="text-base"
                        />
                      </span>
                    </div>
                    <div
                      class="flex flex-col gap-2 border-t-2 border-gray-300 py-2"
                      v-if="
                        showReply &&
                        listReply.length > 0 &&
                        rev.id == listReply[0].idh
                      "
                    >
                      <div class="font-bold">
                        <span
                          >By
                          <span class="text-primary">{{
                            listReply[0].namacustomer
                          }}</span></span
                        >
                      </div>
                      <div class="flex flex-row gap-2 items-center">
                        <span class="bg-primary rounded-md text-white p-2"
                          >Seller</span
                        >
                        <span>{{ dateText(listReply[0].tgl) }}</span>
                      </div>
                      <div class="flex">
                        <span>{{ listReply[0].desc }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="rounded-lg grid font-bold text-xl text-center"
                  :class="{ hidden: activeTab != 3 }"
                  id="contacts"
                  role="tabpanel"
                  aria-labelledby="contacts-tab"
                >
                  No review available.<br />Be the first to review this trip!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid items-center" v-else>
          <div class="mx-auto mt-10">
            <img :src="iconTrip" :alt="textLabel" class="h-40 m-auto" />
            <span class="font-bold text-xl text-center" v-if="idWilayah == ''"
              >Select city & adventure type</span
            >
            <span class="font-bold text-xl text-center" v-else
              >Select other city or adventure type</span
            >
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import CardPaket from "../components/CardPaket";
import VueSlickCarousel from "vue-slick-carousel";
import Vue from "vue";
import { gmapApi } from "vue2-google-maps";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { Carousel, Slide } from "vue-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "DetailPaket",
  components: {
    Header,
    Footer,
    CardPaket,
    Carousel,
    Slide,
    LMap,
    LTileLayer,
    LMarker,
    VueSlickCarousel,
  },
  data: () => ({
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapOptions: {
      zoomSnap: 0.5,
    },
    apiTripwe: process.env.VUE_APP_TRIPWE_PUBLIC_API,
    apiSaebo: process.env.VUE_APP_SAEBO_PUBLIC_API,
    activeTab: 0,
    idWilayah: "",
    selectedPaket: {},
    listKota: [],
    listDock: [],
    listPaket: [],
    listReview: [],
    listReply: [],
    markers: [],
    showReply: false,
    settings: {
      arrows: true,
      dots: false,
      infinite: false,
      autoplay: false,
      swipeToSlide: true,
    },
  }),
  created() {
    this.loadCity();
    //   this.loadDock();
    if (
      this.$store.state.idWilayah != "" &&
      this.$store.state.idWilayah != null
    ) {
      this.idWilayah = this.$store.state.idWilayah;
      this.handleCityChange();
    }
  },
  computed: {
    google: gmapApi,
    totalReview() {
      return this.listReview.length;
    },
    hargaMinDetailPaket() {
      let harga = {
        harga: 0,
        harga_normal: 0,
      };
      if (
        this.selectedPaket != null &&
        this.selectedPaket?.detail != null &&
        this.selectedPaket?.detail.length > 1
      ) {
        const det = this.selectedPaket;
        let lowes = null;
        let lowesObj = {};
        for (const key in det.detail) {
          if (Object.hasOwnProperty.call(det.detail, key)) {
            const element = det.detail[key];
            if (
              lowes == null ||
              (lowes != null && lowes > parseInt(element.harga, 10))
            ) {
              lowes = parseInt(element.harga, 10);
              lowesObj = element;
            }
          }
        }
        harga.harga = lowesObj.harga;
        harga.harga_normal = lowesObj.harga_normal;
      } else if (this.selectedPaket?.detail.length == 1) {
        // harga = this.selectedPaket.detail[0]['harga'];
        harga.harga = this.selectedPaket.detail[0]["harga"];
        harga.harga_normal = this.selectedPaket.detail[0]["harga_normal"];
      }
      return harga;
    },
    textLabel() {
      let str = "";
      switch (this.$route.params.slug) {
        case "wateradventure":
          str = "Watersport";
          break;
        case "landadventure":
          str = "Land Adventure";
          break;
        case "skyadventure":
          str = "Sky Adventure";
          break;

        default:
          str = "Watersport";
          break;
      }
      return str;
    },
    iconTrip() {
      let def = require("@/assets/icons/icon-water-advanture.svg");
      switch (this.$route.params.slug) {
        case "wateradventure":
          def = require("@/assets/icons/icon-water-advanture.svg");
          break;
        case "landadventure":
          def = require("@/assets/icons/icon-land-advanture.svg");
          break;
        case "skyadventure":
          def = require("@/assets/icons/icon-sky-advanture.svg");
          break;
        default:
          def = require("@/assets/icons/icon-water-advanture.svg");
          break;
      }
      return def;
    },
  },
  methods: {
    paketAfterChange(index) {
      var paket = this.listPaket[index];
      this.selectPaket(paket);
    },
    selectPaket(val) {
      this.selectedPaket = val;
    },
    dateText(str) {
      var a = this.$moment();
      var b = this.$moment(str);
      var c = a.diff(b, "days");
      if (c < 7) {
        return c + " Days Ago";
      } else if (c >= 7 && c <= 28) {
        c = a.diff(b, "weeks");
        return c + " Weeks Ago";
      } else {
        return b.format("YYYY-MM-DD HH:mm:ss");
      }
    },
    setPackage() {
      this.$store.commit("setPackage", this.selectedPaket);
      this.$router.push({
        name: "CheckoutPaket",
        params: {
          slug: this.$route.params.slug,
          trip: this.$route.params.trip,
        },
      });
    },
    async handleRedirect(trip) {
      await this.$router.replace({
        name: "DetailPaket",
        params: { slug: this.$route.params.slug, trip: trip },
      });
      this.loadPaket();
    },
    handleReply(detail) {
      this.showReply = !this.showReply;
      if (this.showReply == true) {
        this.loadReply(detail);
      }
    },
    async loadCity() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var bodyFormData = new FormData();
        bodyFormData.append("txtLat", "");
        bodyFormData.append("txtLong", "");
        bodyFormData.append("txtLimit", "50");
        bodyFormData.append("txtJenis", "s");
        var params = {
          txtLat: "",
          txtLong: "",
          txtLimit: "50",
          txtJenis: "s",
          txtFilter: "",
        };
        var url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_v19.php`;
        if (
          this.$route.params.slug == "landadventure" &&
          this.$route.params.trip == "offroad"
        ) {
          url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_offroad_v24.php`;
          bodyFormData.append("txtFilter", "d");
          params.txtFilter = "d";
        } else if (
          this.$route.params.slug == "landadventure" &&
          this.$route.params.trip == "onroad"
        ) {
          url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_onroad_v24.php`;
          bodyFormData.append("txtFilter", "d");
          params.txtFilter = "d";
        } else if (this.$route.params.slug == "skyadventure") {
          url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_sky_v24.php`;
          bodyFormData.append("txtFilter", "d");
          params.txtFilter = "d";
        } else {
          bodyFormData.append("txtFilter", "a");
          params.txtFilter = "a";
        }
        const res = await this.$http.post(url, bodyFormData);
        if (res.data != null) {
          this.listKota = res.data;
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    handleCityChange() {
      this.$store.commit("setIdWilayah", this.idWilayah);
      this.loadPaket();
    },
    async loadPaket() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var kode = "0";
        if (this.$route.params.slug == "wateradventure") {
          kode = "0";
        } else if (this.$route.params.slug == "landadventure") {
          if (this.$route.params.trip == "offroad") {
            kode = "1";
          } else if (this.$route.params.trip == "onroad") {
            kode = "2";
          }
        } else if (this.$route.params.slug == "skyadventure") {
          kode = "4";
        }
        if (this.idWilayah == "") {
          this.listPaket = [];
          this.selectedPaket = {};
          return;
        }
        const res = await this.$http.post(`${this.$apiSaeboTWV2}/ListOrderV4`, {
          id: "UATALLPAKET",
          kodecustomer: this.$store.state?.user?.id_user ?? "",
          kode: kode,
          wilayah: this.idWilayah,
        });

        this.listPaket = res.data;
        if (this.listPaket.length > 0) {
          this.selectedPaket = this.listPaket[0];
          this.loadReview();
        }
      } catch (error) {
        this.$toasted.global.error(
          "Fail to get data trips, please try again later"
        );
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    loadReview() {
      var kode = "2";
      if (this.$route.params.slug == "wateradventure") {
        kode = "2";
      } else if (this.$route.params.slug == "landadventure") {
        if (this.$route.params.trip == "offroad") {
          kode = "3";
        } else if (this.$route.params.trip == "onroad") {
          kode = "4";
        }
      } else if (this.$route.params.slug == "skyadventure") {
        kode = "9";
      }
      Vue.axios
        .post("https://tripwe.id/apptripwe/review/getreviewh", {
          paket: this.selectedPaket.id,
          jenis: kode,
        })
        .then((response) => {
          this.listReview = response.data?.data ?? [];
          return;
        });
    },
    loadTotalReply(detail) {
      var kode = "2";
      if (this.$route.params.slug == "wateradventure") {
        kode = "2";
      } else if (this.$route.params.slug == "landadventure") {
        if (this.$route.params.trip == "offroad") {
          kode = "3";
        } else if (this.$route.params.trip == "onroad") {
          kode = "4";
        }
      } else if (this.$route.params.slug == "skyadventure") {
        kode = "9";
      }
      Vue.axios
        .post("https://tripwe.id/apptripwe/review/getreviewe", {
          idh: detail.id,
          jenis: kode,
        })
        .then((response) => {
          this.listReview = response.data?.data ?? [];
          return;
        });
    },
    loadReply(detail) {
      var kode = "2";
      if (this.$route.params.slug == "wateradventure") {
        kode = "2";
      } else if (this.$route.params.slug == "landadventure") {
        if (this.$route.params.trip == "offroad") {
          kode = "3";
        } else if (this.$route.params.trip == "onroad") {
          kode = "4";
        }
      } else if (this.$route.params.slug == "skyadventure") {
        kode = "9";
      }
      Vue.axios
        .post("https://tripwe.id/apptripwe/review/getreviewd", {
          idh: detail.id,
          jenis: kode,
        })
        .then((response) => {
          this.listReply = response.data?.data ?? [];
          return;
        });
    },
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },
  },
};
</script>
