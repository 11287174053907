<template>
  <div class="m-2">
    <div
      class="flex justify-center pb-1"
      :class="{ 'border-b-4 border-yellow': checkSelected }"
    >
      <div
        class="block rounded-2xl shadow-lg bg-white max-w-md text-center w-110"
      >
        <div
          class="py-3 px-6 border-b border-gray-300 bg-primary text-white rounded-tr-2xl rounded-tl-2xl"
        >
          {{ $props.detail.nama }}
        </div>
        <div class="grid grid-cols-5 gap-2 p-2">
          <div class="col-span-2 md:max-h-32 h-full relative">
            <img
              :src="$props.detail.galeri[0].foto"
              alt="Proday trip"
              class="object-cover rounded-2xl h-full w-full"
            />
            <span
              class="absolute bottom-2 right-2 text-2xl cursor-pointer"
              @click="toggleWishlist"
              :class="{
                'text-yellow': $props.detail.idw != '0',
                'text-white': $props.detail.idw == '0',
              }"
              ><font-awesome-icon icon="fa-solid fa-heart"
            /></span>
          </div>
          <div
            class="col-span-3 flex flex-col gap-2 justify-between text-xs sm:text-sm text-left p-2"
          >
            <div class="font-bold">{{ $props.detail.nama_toko }}</div>
            <div class="flex flex-row justify-between items-center">
              <font-awesome-icon
                icon="fa-regular fa-clock"
                :style="{ color: 'black', fontSize: '18px' }"
              />
              &nbsp; {{ $props.detail.durasi }} {{ $props.detail.satuan }}
              <div class="flex-1"></div>
              <div class="flex items-center">
                <img
                  v-if="$props.detail.level === '3'"
                  src="@/assets/icons/ic-level-advance.svg"
                  class="w-10"
                  :title="$props.detail.level"
                  :alt="$props.detail.level"
                />
                <img
                  v-else-if="$props.detail.level === '2'"
                  src="@/assets/icons/ic-level-intermediate.svg"
                  class="w-10"
                  :title="$props.detail.level"
                  :alt="$props.detail.level"
                />
                <img
                  v-else
                  src="@/assets/icons/ic-level-beginner.svg"
                  class="w-10"
                  :title="$props.detail.level"
                  :alt="$props.detail.level"
                />
              </div>
              <span class="font-semibold text-xs sm:text-sm">
                {{
                  $props.detail.level === "3"
                    ? "Advance"
                    : $props.detail.level === "2"
                    ? "Intermediate"
                    : $props.detail.level === "1" && "Beginner"
                }}
              </span>
            </div>
            <div class="text-xs truncate" :title="$props.detail.category">
              Categories: <br />
              {{ $props.detail.category }}
            </div>
            <div class="text-xs">
              Start From:
              <span
                class="font-bold text-gray-500 text-sm line-through mr-2"
                v-if="cheapestPrice.harga != cheapestPrice.harga_normal"
                >{{ cheapestPrice.harga_normal | toCurrency }}</span
              ><span class="font-bold text-sm">{{
                cheapestPrice.harga | toCurrency
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },
    selectedPaket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  created() {
  },
  computed: {
    checkSelected() {
      return this.$props.selectedPaket.id === this.$props.detail.id;
    },
    cheapestPrice: function () {
      let harga = {
        harga: 0,
        harga_normal: 0,
      };
      if (
        this.detail != null &&
        this.detail?.detail != null &&
        this.detail?.detail.length > 1
      ) {
        const det = this.detail;
        let lowes = null;
        let lowesObj = {};
        for (const key in det.detail) {
          if (Object.hasOwnProperty.call(det.detail, key)) {
            const element = det.detail[key];
            if (
              lowes == null ||
              (lowes != null && lowes > parseInt(element.harga, 10))
            ) {
              lowes = parseInt(element.harga, 10);
              lowesObj = element;
            }
          }
        }
        harga.harga = lowesObj.harga;
        harga.harga_normal = lowesObj.harga_normal;
      } else if (this.detail?.detail.length == 1) {
        // harga = this.detail.detail[0]['harga'];
        harga.harga = this.detail.detail[0]["harga"];
        harga.harga_normal = this.detail.detail[0]["harga_normal"];
      }
      return harga;
    },
  },
  methods: {
    toggleWishlist() {
      if (this.$props.detail.idw != "0") {
        this.removeWishlist();
      } else {
        this.addWishlist();
      }
    },
    async addWishlist() {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        paket: this.$props.detail.id,
        toko: this.$props.detail.id_toko,
        jenis: parseInt(this.$props.detail.tipe, 10) + 2,
      };

      var res = await this.$http.post(
        `${this.apiTripweWeb}/wishlist/add`,
        datapost
      );
      if (res.data.status == 102) {
        this.$props.detail.idw = res.data.data.id.toString();
      }
      this.$store.commit("setOverlayLoading", false);
    },
    async removeWishlist() {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        idwish: this.$props.detail.idw,
      };

      var res = await this.$http.post(
        `${this.apiTripweWeb}/wishlist/delete`,
        datapost
      );
      if (res.data.status == 102) {
        this.$props.detail.idw = "0";
      }
      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
